(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
/*global $, jQuery, alert, console*/

"use strict";
var isMobile = {
    Android: function() {
        return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function() {
        return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function() {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function() {
        return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function() {
        return navigator.userAgent.match(/IEMobile/i);
    },
    any: function() {
        return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows() );
    }
};

var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);


$(document).ready(function () {
    $('.s-header__text-wrap_slider').show();
    controlTopMenu();
    slickSliderControl();
    controlAnimatedScroll();
    detectSeenElements();
    copyOnClipboard();
    customAboutTabController();
    customTabController();
    showMoreBtnController();
    addInlineSvg();
    controlAgileArrow();
    contactFormsConfig();
    blogTagsController();
    controlCalculator();
    controlVacCalculator();

    $('.sidenav').sidenav();

    $('select').formSelect();

    $('.tooltipped').tooltip();
    $('.collapsible').collapsible();
    $('.modal').modal();
    $('.dropdown-link_material').dropdown();
    $('.tabs').tabs();
    $('.lazy').Lazy();
    $('.s-case-description-content_fixto').fixTo('.s-case-description__content-wrap', {
        top: 120
    });
    $('.s-blog-list__baners').fixTo('.s-blog-list__content', {
        top: 120
    });
    $('.s-benefits__block--left').fixTo('.s-benefits__content', {
        top: 120
    });
    $('.s-faq__part--right').fixTo('.s-faq__content', {
        top: 120
    });

    $('.collapsible-header').on('click', function (e) {
        e.preventDefault();
    });

    $('a').on('click', function (e) {
        if ($(this).attr('href') == '#' || $(this).attr('href') == '' || $(this).attr('href') == '!#' || $(this).attr('href') == '#!') {
            e.preventDefault();
        }
    });


    $('.s-case-description__block_slider').each(function () {
        $(this).magnificPopup({
            delegate: 'a', // child items selector, by clicking on it popup will open
            type: 'image',
            tLoading: 'Loading image #%curr%...',
            mainClass: 'mfp-img-mobile',
            gallery: {
                enabled: true,
                navigateByImgClick: true,
                preload: [0,1] // Will preload 0 - before current, and 1 after the current image
            },
            image: {
                tError: '<a href="%url%">The image #%curr%</a> could not be loaded.'
            }
        });
    })


    $('body').on('click','.gdpr-cookie-notice-modal-footer-item-save', function () {
        $('.gdpr-cookie-notice-modal').css('display', 'none');
        $('.gdpr-cookie-notice').css('display', 'none');
    });

    $('body').on('click','.gdpr-cookie-notice-modal-footer-item-statement', function () {
        $('#privacy_popup').modal('open');
    });

    $('#videoCustomers').on('click', function() {
        $(this).closest('.video-wrap').addClass('played');
        if(!$(this).hasClass('played')) {
            this.play();
        }
        $(this).addClass('played');
    });

    if(isMobile.any()) {
        $('#videoCustomers').closest('.video-wrap').addClass('played');
    }

    controlVoteBtn()
    function controlVoteBtn() {
        $('.votebtn__modal-trigger').on('click', function (e) {
            e.preventDefault();

            $(this).removeClass('active');
            $('.votebtn__modal').addClass('active')
        })

        $('.votebtn__modal-close').on('click', function (e) {
            e.preventDefault();

            $(this).closest('.votebtn__modal').removeClass('active');
            $('.votebtn__modal-trigger').addClass('active')
        })
    }


    controlHealthcareApplicationTabs();
    function controlHealthcareApplicationTabs() {
        $('.s-healthcare-application-tabs__tab').on('click', function () {
            var curIndex = $(this).attr('data-index');

            $('.s-healthcare-application-tabs__tab').each(function () {
                $(this).removeClass('active')
                if ($(this).attr('data-index') == curIndex) {
                    $(this).addClass('active')
                }
            })

            $('.s-healthcare-application-tabs__result-block').each(function () {
                $(this).removeClass('active')
                if ($(this).attr('data-index') == curIndex) {
                    $(this).addClass('active')
                }
            })

        })
    }

    controlHealthcareTabs();
    function controlHealthcareTabs() {
        $('.s-healthcare-tabs__content-block').on('click', function () {
            var curIndex = $(this).attr('data-index');

            $('.s-healthcare-tabs__content-block').each(function () {
                $(this).removeClass('active')
                if ($(this).attr('data-index') == curIndex) {
                    $(this).addClass('active')
                }
            })

            $('.s-healthcare-tabs__image-block').each(function () {
                $(this).removeClass('active')
                if ($(this).attr('data-index') == curIndex) {
                    $(this).addClass('active')
                }
            })

        })
    }

    controlStepForm();
    function controlStepForm() {
        $('.step-btn_next').on('click', function (e) {
            e.preventDefault();
            var flag = false;
            $(this).closest('.step-block').find('.form-control').each(function () {
                if($(this).hasClass('invalid')) {
                    flag = false;
                    return false;
                } else {
                    flag = true;
                }
            });

            if(flag) {
                $(this).closest('.step-block').removeClass('active').next('.step-block').addClass('active');
                $(this).closest('form').find('.form-control').each(function() {
                    $(this).removeClass('invalid');
                })
                $(this).closest('form').find('.form-error').each(function() {
                    $(this).remove();
                })
            }
        });

        $('.step-btn_back').on('click', function (e) {
            e.preventDefault();
            $(this).closest('.step-block').removeClass('active').prev('.step-block').addClass('active');
        })
    }

    sServicesColapseControl()
    function sServicesColapseControl() {
        var countBlocks = 0;

        $('.s-services-colapse__block').each(function () {
            countBlocks++;
        });

        if (countBlocks < 7) {
            $('.s-services-colapse__content').addClass('active')
        };

        $('.s-services-colapse__link').on('click', function (e) {
            e.preventDefault();

            $('.s-services-colapse__content').addClass('active')
        })


    }

    sBlockHealthCareControl()
    function sBlockHealthCareControl() {
        var countBlocks = 0;

        $('.s-blocks-healthcare__block').each(function () {
            countBlocks++;
        });

        if (countBlocks < 5) {
            $('.s-blocks-healthcare__content').addClass('active')
        };

        $('.s-blocks-healthcare__btn').on('click', function (e) {
            e.preventDefault();

            $('.s-blocks-healthcare__content').addClass('active')
        })


    }


    sFrontCompetencesControl()
    function sFrontCompetencesControl() {
        $('.s-front-competences__tab').on('click', function () {
            var curId = $(this).attr('data-index')

            $('.s-front-competences__tab').each(function () {
                $(this).removeClass('active')
                if ($(this).attr('data-index') == curId) {
                    $(this).addClass('active')
                }
            })

            $('.s-front-competences__item').each(function () {
                $(this).removeClass('active')
                if ($(this).attr('data-index') == curId) {
                    $(this).addClass('active')
                }
            })

            $('.s-front-competences__img').each(function () {
                $(this).removeClass('active')
                if ($(this).attr('data-index') == curId) {
                    $(this).addClass('active')
                }
            })
        })
    }

    automativeServicesControl()
    function automativeServicesControl() {
        $('.s-automative-solutions__list-item-wrap').on('click', function () {
            var curId = $(this).attr('data-index')

            $('.s-automative-solutions__list-item-wrap').each(function () {
                $(this).removeClass('active')
                if($(this).attr('data-index') == curId) {
                    $(this).addClass('active')
                }
            })

            $('.s-automative-solutions__bg-img').each(function () {
                $(this).removeClass('active')
                if($(this).attr('data-index') == curId) {
                    $(this).addClass('active')
                }
            })

            $('.s-automative-solutions__text-wrap').each(function () {
                $(this).removeClass('active')
                if($(this).attr('data-index') == curId) {
                    $(this).addClass('active')
                }
            })
        })
    }



    monitoringControl();
    function monitoringControl() {
        $('.s-monitoring__list-item').on('click', function () {
            var curId = $(this).attr('data-index')
            if ($(this).hasClass('s-monitoring__list-item-2')) {
                $('.s-monitoring__list-item').each(function () {
                    $(this).removeClass('active')
                    if($(this).attr('data-index') == curId) {
                        $(this).addClass('active')
                    }
                })
            } else {
                $('.s-monitoring__list-item').each(function () {
                    $(this).removeClass('active')
                    if($(this).attr('data-index') == curId) {
                        $(this).addClass('active')
                    }
                })
            }

            $('.s-monitoring__block').each(function () {
                $(this).removeClass('active')
                if($(this).attr('data-index') == curId) {
                    $(this).addClass('active')
                }
            })

            $('.s-monitoring__bg-img').each(function () {
                $(this).removeClass('active')
                if($(this).attr('data-index') == curId) {
                    $(this).addClass('active')
                }
            })
        })
    }


    sliderLinkControl();
    function sliderLinkControl() {
        $('.s-slider-links__link-wrap--desctp .s-slider-links__link').on('click', function (e) {
            e.preventDefault();

            var curId = $(this).attr('data-index');

            $('.s-slider-links__link-wrap--desctp .s-slider-links__link').each(function () {
                $(this).removeClass('active');
            })
            $(this).addClass('active');


            $(this).closest('.s-slider-links').find('.s-slider-links__result').each(function () {
                $(this).removeClass('active');

                if ($(this).attr('data-index') == curId) {
                    $(this).addClass('active');
                }
            })

            $('html, body').animate({
                scrollTop: $(this).closest('.s-slider-links').find('.s-slider-links__block-res').offset().top - 100
            }, 500);
        })
    }


    function controlCalculator() {

        $('.s-calculator__block-item').on('click', function () {
            var checked = false;
            if ($(this).closest('.s-calculator__block').hasClass('radioBlock')) {
                $(this).closest('.s-calculator__block').find('.s-calculator__block-item').each(function () {
                    $(this).removeClass('active');
                });
                $(this).addClass('active');
            } else {
                $(this).toggleClass('active');
            }

            $(this).closest('.s-calculator__block').find('.s-calculator__block-item').each(function () {
                if ($(this).hasClass('active')) {
                    checked = true;
                }
            });

            if (checked) {
                $(this).closest('.s-calculator__block').addClass('checked');
            } else {
                $(this).closest('.s-calculator__block').removeClass('checked');
            }
        });

        $('.next-btn').on('click', function (e) {
            e.preventDefault();
            $(this).closest('.s-calculator__block').removeClass('active').next('.s-calculator__block').addClass('active');
            $('html, body').animate({
                scrollTop: $('#calculatorSection').offset().top - 86
            }, 500);
        });
        $('.skip-btn').on('click', function (e) {
            e.preventDefault();
            $(this).closest('.s-calculator__block').removeClass('active').next('.s-calculator__block').addClass('active');
            $('html, body').animate({
                scrollTop: $('#calculatorSection').offset().top - 86
            }, 500);
        });
        $('.prev-btn').on('click', function (e) {
            e.preventDefault();
            $(this).closest('.s-calculator__block').removeClass('active').prev('.s-calculator__block').addClass('active');
            $('html, body').animate({
                scrollTop: $('#calculatorSection').offset().top - 86
            }, 500);
        });

        $('#calculatorStep1 .s-calculator__block-item').on('click', function () {
            if ($('#calculatorStep1 .s-calculator__block-item:first-child').hasClass('active')) {
                $('#calculatorSection').addClass('business');
            } else {
                $('#calculatorSection').removeClass('business');
            }

        })

    }

    function controlVacCalculator() {
        $('.s-calculator-vac__block-item.check-item').on('click', function () {
            var checked = false;
            if ($(this).closest('.s-calculator-vac__block').hasClass('radioBlock')) {
                $(this).closest('.s-calculator-vac__block').find('.s-calculator-vac__block-item').each(function () {
                    $(this).removeClass('active');
                });
                $(this).addClass('active');
            } else {
                $(this).toggleClass('active');
            }

            $(this).closest('.s-calculator-vac__block').find('.s-calculator-vac__block-item').each(function () {
                if ($(this).hasClass('active')) {
                    checked = true;
                }
            });

            if (checked) {
                $(this).closest('.s-calculator-vac__block').addClass('checked');
            } else {
                $(this).closest('.s-calculator-vac__block').removeClass('checked');
            }
        });

        $('.s-calculator-vac__block-item--yes').on('click', function (e) {
            e.preventDefault();
            $('.s-calculator-vac__block--first .formWP').addClass('hidden')

            $('.s-calculator-vac__block-line').each(function () {
                $(this).removeClass('active');
            });
            $('.s-calculator-vac__block-line--yes').addClass('active');
        })

        $('.s-calculator-vac__block-item--no').on('click', function (e) {
            e.preventDefault();
            $('.s-calculator-vac__block--first .formWP').removeClass('hidden')


            $('.s-calculator-vac__block-line').each(function () {
                $(this).removeClass('active');
            });
            $('.s-calculator-vac__block-line--no').addClass('active');
        });




        $('.s-calculator-vac__block--first .next-btn-vac').on('click', function (e) {
            e.preventDefault();
            if ($('.s-calculator-vac__block--first').hasClass('checked')) {

                $(this).closest('.s-calculator-vac__block').removeClass('active');
                if ($('.s-calculator-vac__block-item--yes').hasClass('active')) {
                    $('.s-calculator-vac__block-line').each(function () {
                        $(this).removeClass('active');
                    });
                    $('.s-calculator-vac__block-line--yes').addClass('active');
                }
                if ($('.s-calculator-vac__block-item--no').hasClass('active')) {
                    $('.s-calculator-vac__block-line').each(function () {
                        $(this).removeClass('active');
                    });
                    $('.s-calculator-vac__block-line--no').addClass('active');
                }

                $('.s-calculator-vac__block-line.active').find('.s-calculator-vac__block-normal--first').addClass('active');

                $('html, body').animate({
                    scrollTop: $('#calculatorVacSection').offset().top - 86
                }, 500);
            }
        });

        $('.s-calculator-vac__block-normal .next-btn-vac').on('click', function (e) {
            e.preventDefault();
            if ($(this).closest('.s-calculator-vac__block-normal').hasClass('s-calculator-vac__block-normal--last')) {
                $(this).closest('.s-calculator-vac__block-normal--last').removeClass('active');
                $('.s-calculator-vac__block--form').addClass('active')
                $('html, body').animate({
                    scrollTop: $('#calculatorVacSection').offset().top - 86
                }, 500);
            } else {
                $(this).closest('.s-calculator-vac__block-normal').removeClass('active').next('.s-calculator-vac__block-normal').addClass('active');
                $('html, body').animate({
                    scrollTop: $('#calculatorVacSection').offset().top - 86
                }, 500);
            }
        });
        $('.s-calculator-vac__block-normal .skip-btn-vac').on('click', function (e) {
            e.preventDefault();
            if ($(this).closest('.s-calculator-vac__block-normal').hasClass('s-calculator-vac__block-normal--last')) {
                $(this).closest('.s-calculator-vac__block-normal--last').removeClass('active');
                $('.s-calculator-vac__block--form').addClass('active')
                $('html, body').animate({
                    scrollTop: $('#calculatorVacSection').offset().top - 86
                }, 500);
            } else {
                $(this).closest('.s-calculator-vac__block-normal').removeClass('active').next('.s-calculator-vac__block-normal').addClass('active');
                $('html, body').animate({
                    scrollTop: $('#calculatorVacSection').offset().top - 86
                }, 500);
            }
        });
        $('.s-calculator-vac__block-normal .prev-btn-vac').on('click', function (e) {
            e.preventDefault();
            if ($(this).closest('.s-calculator-vac__block-normal').hasClass('s-calculator-vac__block-normal--first')) {

                $(this).closest('.s-calculator-vac__block-normal').removeClass('active').closest('.s-calculator-vac__block-line').removeClass('active');
                $('.s-calculator-vac__block--first').addClass('active')
                $('html, body').animate({
                    scrollTop: $('#calculatorVacSection').offset().top - 86
                }, 500);
            } else {
                $(this).closest('.s-calculator-vac__block-normal').removeClass('active').prev('.s-calculator-vac__block-normal').addClass('active');
                $('html, body').animate({
                    scrollTop: $('#calculatorVacSection').offset().top - 86
                }, 500);
            }
        });
        $('.s-calculator-vac__block--form .prev-btn-vac').on('click', function (e) {
            e.preventDefault();

            $(this).closest('.s-calculator-vac__block').removeClass('active');
            $('.s-calculator-vac__block-line.active').find('.s-calculator-vac__block-normal--last').addClass('active');
            $('html, body').animate({
                scrollTop: $('#calculatorVacSection').offset().top - 86
            }, 500);
        })


        $('.s-calculator-vac__minus-btn').on('click', function (e) {
            e.preventDefault();

            var curNum = +$(this).next('.s-calculator-vac__select-res').text();
            if (curNum != 0) {
                curNum -= 1;
                $(this).next('.s-calculator-vac__select-res').text(curNum);
            }
        })
        $('.s-calculator-vac__plus-btn').on('click', function (e) {
            e.preventDefault();

            var curNum = +$(this).prev('.s-calculator-vac__select-res').text();
            curNum += 1;
            $(this).prev('.s-calculator-vac__select-res').text(curNum);

            $(this).closest('.s-calculator-vac__block').addClass('checked');
        })



        $('.s-calculator-vac__select-calc-add-btn').on('click', function (e) {
            e.preventDefault();

            if (+$(this).closest('.s-calculator-vac__select-item').find('.s-calculator-vac__select-res').text() != 0) {
                var curName = $(this).closest('.s-calculator-vac__select-item').find('.s-calculator-vac__select-item-name').text();
                var curPos = $(this).closest('.s-calculator-vac__select-item').find('select').val();
                var curNumber = $(this).closest('.s-calculator-vac__select-item').find('.s-calculator-vac__select-res').text();
                $(this).closest('.s-calculator-vac__select-item').find('.s-calculator-vac__select-res').text('0')


                $(this).closest('.s-calculator-vac__block').find('.s-calculator-vac__block-item--select-results-wrap').append("<div class='s-calculator-vac__block-item--select-result'><span>" + curName + " (" + curPos + ") - " + curNumber + " specialist</span><a href='#' class='s-calculator-vac__remove-result'><img src='assets/images/svg/cross-icon.svg'></a></div>")
            }

            $(this).closest('.s-calculator-vac__block').addClass('checked');

        })

        $('.s-calculator-vac__select-item-top select').on('change', function () {
            $(this).closest('.s-calculator-vac__select-item').find('.s-calculator-vac__select-res').text('0')
        })

        $('#calculatorVacSection').on('click', '.s-calculator-vac__remove-result', function (e) {
            e.preventDefault();

            $(this).closest('.s-calculator-vac__block-item--select-result').remove();
        })


        if ($('#calculatorVacRange').length) {
            var slider = document.getElementById('calculatorVacRange');
            noUiSlider.create(slider, {
                start: 0,
                connect: 'lower',
                tooltips: [true],
                step: 1,
                orientation: 'horizontal', // 'horizontal' or 'vertical'
                range: {
                    'min': 0,
                    'max': 24
                },
                format: wNumb({
                    decimals: 0,
                    suffix: ' month(s)'
                }),
                pips: {
                    mode: 'values',
                    values: [6, 12, 18, 24],
                    density: 4,
                    format: wNumb({
                        decimals: 0,
                        suffix: ' months'
                    })
                }
            });
        }



    }


    function blogTagsController() {
        $('.s-blog-list__tag').on('click', function () {
            $('.s-blog-list__tag.active').removeClass('active');
            $(this).addClass('active');
        })
    }

    // function customPrtfolioTabController() {
    //
    //     if(window.location.pathname === '/stories.html' || window.location.pathname === '/stories') {
    //         var hash = window.location.hash;
    //
    //         if(hash !== '') {
    //             $('html, body').animate({
    //                 scrollTop: $('#portfolioSection').offset().top - 86
    //             }, 500);
    //
    //             $('.s-portfolio__tabs-wrap .s-portfolio__tab').each(function () {
    //                 $(this).removeClass('active');
    //             });
    //
    //             $("div[data-tab='industries']").eq(0).addClass('active');
    //
    //             var curCat = 'industries';
    //
    //             $('.s-portfolio__tabs-wrap .s-portfolio__tab').each(function () {
    //                 $(this).removeClass('active');
    //                 if($(this).attr('data-tab') == curCat) {
    //                     $(this).addClass('active');
    //                 }
    //             });
    //             $('.s-portfolio__tags-wrap .tags-block').each(function () {
    //                 $(this).removeClass('active');
    //                 if($(this).attr('data-tab') == curCat) {
    //                     $(this).addClass('active');
    //                 }
    //             });
    //             $('.tags-block .tag').each(function () {
    //                 $(this).removeClass('active');
    //
    //                 if(hash) {
    //                     $("div[data-anchor='" + hash + "']").addClass('active');
    //                     setBlocks($("div[data-anchor='" + hash + "']"));
    //                 }
    //             });
    //         }
    //     }
    //
    //     controlShowMore();
    //
    //     function controlShowMore() {
    //         var projectCounter = 0;
    //         var showProjectCounter = 6;
    //         var stepShowProjects = 6;
    //
    //         function controlShowMoreBtn() {
    //             if(projectCounter <= showProjectCounter) {
    //                 $('.show-more__link_portfolio').hide();
    //             } else {
    //                 $('.show-more__link_portfolio').show();
    //             }
    //         }
    //
    //         function controlShowProjects() {
    //             $('.projects-wrap .project-block.active').each(function () {
    //                 if($(this).attr('data-count') > showProjectCounter) {
    //                     $(this).hide();
    //                 } else {
    //                     $(this).show();
    //                 }
    //             });
    //         }
    //
    //         $('.projects-wrap .project-block.active').each(function () {
    //             projectCounter ++;
    //             $(this).attr('data-count', projectCounter);
    //         });
    //
    //
    //         controlShowProjects();
    //         controlShowMoreBtn();
    //
    //
    //         $('.show-more__link_portfolio').on('click', function (e) {
    //             e.preventDefault();
    //             showProjectCounter = showProjectCounter + stepShowProjects;
    //
    //             controlShowProjects();
    //             controlShowMoreBtn();
    //         })
    //
    //     }
    //
    //
    //     // $('.s-portfolio__tabs-wrap .s-portfolio__tab').on('click', function () {
    //     //     var curTab = $(this).attr('data-tab');
    //     //     $('.s-portfolio__tabs-wrap .s-portfolio__tab').each(function () {
    //     //         $(this).removeClass('active');
    //     //         if($(this).attr('data-tab') == curTab) {
    //     //             $(this).addClass('active');
    //     //         }
    //     //     });
    //     //     $('.s-portfolio__tags-wrap .tags-block').each(function () {
    //     //         $(this).removeClass('active');
    //     //         $(this).find('.tag_all').addClass('active');
    //     //         if($(this).attr('data-tab') == curTab) {
    //     //             $(this).addClass('active');
    //     //         }
    //     //     });
    //     //     $('.tags-block .tag').each(function () {
    //     //         if(!$(this).hasClass('tag_all')) {
    //     //             $(this).removeClass('active');
    //     //         }
    //     //     });
    //     //     $('.projects-wrap .project-block').each(function () {
    //     //         $(this).addClass('active');
    //     //     });
    //     //
    //     //     controlShowMore();
    //     // });
    //
    //
    //
    //     $('.tags-block .tag').on('click', function() {
    //         var anchor = $(this).attr('data-anchor');
    //
    //         if (typeof anchor !== typeof undefined && anchor !== false) {
    //             history.pushState(null, null, anchor);
    //         } else {
    //             history.pushState(null, null, window.location.pathname);
    //         }
    //
    //         if($(this).hasClass('tag_all')) {
    //             $('.projects-wrap .project-block').each(function () {
    //                 $(this).addClass('active');
    //             });
    //             $(this).closest('.tags-block').find('.tag').each(function () {
    //                 $(this).removeClass('active');
    //             });
    //             $(this).addClass('active');
    //             controlShowMore();
    //             return;
    //         }
    //
    //         setBlocks($(this));
    //
    //         controlShowMore();
    //     });
    //
    //     function setBlocks(_this) {
    //         var curTag = _this.find('span').text();
    //         _this.closest('.tags-block').find('.tag').each(function () {
    //             $(this).removeClass('active');
    //         });
    //
    //         _this.addClass('active');
    //         $('.projects-wrap .project-block').each(function () {
    //             var thisBlock = $(this);
    //             var flag = false;
    //             thisBlock.find('.tags-wrap').find('.tag').each(function () {
    //                 if($(this).find('span').text() == curTag) {
    //                     flag = true;
    //                 }
    //             });
    //             if (flag && !thisBlock.hasClass('active')) {
    //                 thisBlock.addClass('active');
    //             } else if (!flag) {
    //                 thisBlock.removeClass('active');
    //             }
    //         });
    //     }
    //
    // }

    function controlAgileArrow() {
        $('.chose-link').on('click', function(e) {
            e.preventDefault();
            clearInterval(blinkSWRok);
            $('.s-arrow-work-plan').removeClass('slow-delay');
            $('.s-arrow-work-plan').removeClass('blink');
            var curId = $(this).attr('href').slice(1);
            $('.chose-link').each(function() {
                $(this).removeClass('active');
                if($(this).attr('href').slice(1) == curId) {
                    $(this).addClass('active');
                }
            });
            $('.chose-link-res').each(function () {
                $(this).removeClass('active');
                if($(this).attr('id') == curId) {
                    $(this).addClass('active');
                }
            });
        });
        $('.chose-link-scale').on('click', function(e) {
            e.preventDefault();
            var curId = $(this).attr('href').slice(1);
            $('.chose-link-scale').each(function() {
                $(this).removeClass('active');
            });
            $('.chose-link-res-scale').each(function () {
                $(this).removeClass('active');
                if($(this).attr('id') == curId) {
                    $(this).addClass('active');
                }
            });
            $(this).addClass('active');
        });
        var blinkSWRok = setInterval(function() {
            $('.s-arrow-work-plan').toggleClass('blink');
        },1000);

        $('.s-arrow-work-wrap-mobile').slick({
            variableWidth: true,
            arrows: false,
            centerMode: false,
            infinite: false,
            focusOnSelect: true
        });
    }


    function showMoreBtnController(){
        $('.show-more__link').on('click', function (e) {
            e.preventDefault();
            $(this).addClass('active');
            $(this).closest('section').addClass('show-more_active');
        })
    }



    function customTabController() {
        $('.custom-tabs .tab__link').on('click', function() {
            var currentTab = $(this).attr('data-tab');
            $(this).closest('.custom-tabs').find('.tab__link').each(function() {
                $(this).removeClass('active');
                if($(this).attr('data-tab') == currentTab) {
                    $(this).addClass('active');
                }
            });
            $(this).closest('.custom-tabs').find('.tab__result').each(function() {
                $(this).removeClass('active');
                if($(this).attr('data-tab') == currentTab) {
                    $(this).addClass('active');
                    if($(window).width() <= 600) {
                        $('html, body').animate({
                            scrollTop: $(this).offset().top - 80
                        }, 500);
                    }
                }
            })
        })
    }

    function customAboutTabController() {
        $('.s-about .tab__link').on('click', function() {
            var checkedCountry = $(this).attr('data-country');
            $('.tabs__links-wrap .tab__link').each(function() {
                $(this).removeClass('active');
                if ($(this).attr('data-country') == checkedCountry) {
                    $(this).addClass('active');
                }
            });
            $('.tabs__results-wrap .tab__result').each(function() {
                $(this).removeClass('active');
                if ($(this).attr('data-country') == checkedCountry) {
                    $(this).addClass('active');
                }
            });
            $(this).closest('.s-about').attr('data-country', checkedCountry);

        })
    }


    function copyOnClipboard() {
        if (isSafari) {
            $('.copy-block').each(function () {
                $(this).hide();
            })
        }
        $('.copy-block').on('click', function() {
            var copyText = $(this).prev('a').text();
            navigator.clipboard.writeText(copyText);
            var element = $(this);
            element.addClass('active');
            element.addClass('active-copy');
            setTimeout(function(){ element.removeClass('active'); }, 200);
            setTimeout(function(){ element.removeClass('active-copy'); }, 1000);
        })
    }



    function detectSeenElements() {
        var sections = $('section, .animated-block');

        sections.each(function () {
            var top_of_element = $(this).offset().top + $(window).innerHeight()/2;
            var bottom_of_element = $(this).offset().top + $(this).outerHeight();
            var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
            var top_of_screen = $(window).scrollTop();

            if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)){
                $(this).addClass('seen')
            } else {
                // the element is not visible, do something else
            }
        })

        $(window).scroll(function() {

            sections.each(function () {
                var top_of_element = $(this).offset().top + $(window).innerHeight()/2;
                var bottom_of_element = $(this).offset().top + $(this).outerHeight();
                var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
                var top_of_screen = $(window).scrollTop();

                if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)){
                    $(this).addClass('seen')
                } else {
                    // the element is not visible, do something else
                }
            })
        });
    }

    function controlTopMenu() {
        var scrollHeight = $(window).scrollTop();

        if(scrollHeight  > 0) {
            $('.menu').addClass('not-top');
        } else {
            $('.menu').removeClass('not-top');
        }
        $(window).scroll(function() {
            scrollHeight = $(window).scrollTop();

            if(scrollHeight  > 0) {
                $('.menu').addClass('not-top');
            } else {
                $('.menu').removeClass('not-top');
            }
        });

        $('.desktop-menu .dropdown-trigger').on('click', function(e){
            e.preventDefault();
            if($(this).hasClass('active')) {
                $(this).removeClass('active');
                $('.desktop-menu .dropdown-menu').each(function() {
                    $(this).removeClass('active');
                });
                return
            }
            var curMenu = $(this).attr('data-href');
            $('.desktop-menu .dropdown-trigger').each(function() {
                $(this).removeClass('active');
            });
            $(this).addClass('active');
            $('.desktop-menu .dropdown-menu').each(function() {
                $(this).removeClass('active');
                if($(this).attr('id') == curMenu) {
                    $(this).addClass('active');
                }
            })
        });
        $(document).click(function(event) {
            var target = $(event.target);
            if(!target.closest('.dropdown-trigger').length && !target.hasClass('dropdown-trigger') && !target.closest('.dropdown-menu').length && !target.hasClass('dropdown-menu')) {
                $('.desktop-menu .dropdown-menu').each(function() {
                    $(this).removeClass('active');
                });
                $('.desktop-menu .dropdown-trigger').each(function() {
                    $(this).removeClass('active');
                });
            }
        });

        $(window).resize(function() {

            if ($(window).width() <= 991) {
                $('.desktop-menu .dropdown-menu').each(function() {
                    $(this).removeClass('active');
                })
                $('.desktop-menu .dropdown-trigger').each(function() {
                    $(this).removeClass('active');
                });
            }

        });


        $('.dropdown-menu .dropdown-menu__link').on('mouseenter', function () {
            var curIndex = $(this).attr('data-index');
            $(this).closest('.dropdown-menu__links-wrap').prev('.dropdown-menu__images-wrap').find('.intro-block').each(function () {
                $(this).removeClass('active')
                if($(this).attr('data-index') == curIndex) {
                    $(this).addClass('active')
                }
            })
        });


        $('.dropdown-menu__link--sub').on('mouseenter', function () {
            $('.dropdown-menu__link--sub').each(function () {
                $(this).removeClass('active')
            })

            var curSub = $(this).attr('data-sub');
            $(this).addClass('active');

            $('.sub-block').each(function () {
                $(this).removeClass('active')

                if ($(this).attr('id') == curSub) {
                    $(this).addClass('active')
                }
            })
        })


    }


    controllerSHowSlider();
    function controllerSHowSlider() {
        var curIndex = 1;
        var indexLength = $('.s-how-slider__text-block').length;

        $('.s-how-slider__controller--right').on('click', function () {
            if (curIndex < indexLength) {
                curIndex = curIndex + 1;
            } else {
                curIndex = 1
            }


            $('.s-how-slider__text-block').each(function () {
                $(this).removeClass('active')
                if ($(this).attr('data-index') == curIndex) {
                    $(this).addClass('active')
                }
            })
            $('.s-how-slider__image-block').each(function () {
                $(this).removeClass('active')
                if ($(this).attr('data-index') == curIndex) {
                    $(this).addClass('active')
                }
            })
        })


        $('.s-how-slider__controller--left').on('click', function () {
            if (curIndex == 1) {
                curIndex = indexLength;
            } else {
                curIndex = curIndex - 1;
            }


            $('.s-how-slider__text-block').each(function () {
                $(this).removeClass('active')
                if ($(this).attr('data-index') == curIndex) {
                    $(this).addClass('active')
                }
            })
            $('.s-how-slider__image-block').each(function () {
                $(this).removeClass('active')
                if ($(this).attr('data-index') == curIndex) {
                    $(this).addClass('active')
                }
            })
        })

    };

    function controlAnimatedScroll() {
        $('.animated-scroll').on('click', function (e) {
            e.preventDefault();
            let href = $(this).attr('href');
            let linkHref = href;

            if (href.indexOf('https') != -1) {
                const url = new URL(href);
                linkHref = url.hash;
            }

            $('html, body').animate({
                scrollTop: $(linkHref).offset().top - 60
            }, 500);
        });

        $('.back-top__link').on('click', function (e) {
            e.preventDefault();
            $('html, body').animate({
                scrollTop: 0
            }, 500);
        });
    }

    controlTestingServices()
    function controlTestingServices() {
        $('.s-testing-services__tab').on('mouseenter', function () {
            var curId = $(this).attr('data-index');


            $(this).closest('.s-testing-services').find('.s-testing-services__tab').each(function () {
                $(this).removeClass('active').prevAll('.s-testing-services__tab').removeClass('past')
            })

            $(this).closest('.s-testing-services').find('.s-testing-services__tab').each(function () {
                if ($(this).attr('data-index') == curId) {
                    $(this).addClass('active').prevAll('.s-testing-services__tab').addClass('past')
                }
            })

            $(this).closest('.s-testing-services').find('.s-testing-services__tabs-result').each(function () {
                $(this).removeClass('active')

                if ($(this).attr('data-index') == curId) {
                    $(this).addClass('active')
                }
            })
        })
    }

    controlTabPhone()
    function controlTabPhone() {

        $('.custom-tabs .custom-tabs__tab-link').on('mouseenter', function(e) {
            e.preventDefault();
            $('.custom-tabs .custom-tabs__tab-link').each(function() {
                $(this).removeClass('active');
            });
            $(this).addClass('active');
            var curId = $(this).attr('href').slice(1);
            $('.custom-tab__result-wrap .custom-tab-result').each(function() {
                if ($(this).prop('id') != curId) {
                    $(this).removeClass('active');
                } else {
                    $(this).addClass('active');
                    return
                }
            });
        })

        if ($(window).width() <= 992) {
            $('.s-phone-tab__image-block').slick({
                centerMode: true,
                centerPadding: '60px',
                slidesToShow: 1,
                prevArrow: '<div class="prev-btn"><div class="custom-arrow custom-arrow-left"></div></div>',
                nextArrow: '<div class="next-btn"><div class="custom-arrow custom-arrow-right"></div></div>',
                responsive: [
                    {
                        breakpoint: 600,
                        settings: {
                            centerMode: false,
                            variableWidth: true
                        }
                    }
                ]

            })

            $('.s-phone-tab__image-block').on('afterChange', function(event, slick, currentSlide, nextSlide){
                var cutId = '#phoneTab' + (currentSlide + 1);
                $('.custom-tabs .custom-tabs__tab-link').each(function() {
                    $(this).removeClass('active');
                    if ($(this).attr('href') == cutId) {
                        $(this).addClass('active');
                    }
                });
            });
        }
        $(window).resize(function() {

            if ($(window).width() <= 992 && !$('.s-phone-tab__image-block').hasClass('slick-slider')) {
                $('.s-phone-tab__image-block').slick({
                    centerMode: true,
                    centerPadding: '60px',
                    slidesToShow: 1,
                    prevArrow: '<div class="prev-btn"><div class="custom-arrow custom-arrow-left"></div></div>',
                    nextArrow: '<div class="next-btn"><div class="custom-arrow custom-arrow-right"></div></div>',
                    responsive: [
                        {
                            breakpoint: 600,
                            settings: {
                                centerMode: false,
                                variableWidth: true
                            }
                        }
                    ]
                })
                $('.s-phone-tab__image-block').on('afterChange', function(event, slick, currentSlide, nextSlide){
                    var cutId = '#phoneTab' + (currentSlide + 1);
                    $('.custom-tabs .custom-tabs__tab-link').each(function() {
                        $(this).removeClass('active');
                        if ($(this).attr('href') == cutId) {
                            $(this).addClass('active');
                        }
                    });
                });
            } else if ($(window).width() > 992 && $('.s-phone-tab__image-block').hasClass('slick-slider')) {
                $('.s-phone-tab__image-block').slick('unslick');
            }

        });
    }


    controlStarsForm()
    function controlStarsForm() {

        $('#email_stars').val('stars@altoros.com')

        if (localStorage.getItem(window.location.href)) {
            var curStars = localStorage.getItem(window.location.href)



            $('.s-rate .s-rate__star').each(function () {
                $(this).removeClass('active')
            })
            $('.s-rate .s-rate__star').each(function () {
                if ($(this).attr('data-index') == curStars) {
                    $(this).addClass('active').addClass('chosen').prevAll('.s-rate__star').addClass('active').closest('.s-rate').addClass('checked')
                }
            })


            $('.s-rate .submitButton').remove();
        }


        $('.discount-btn').on('click', function (e) {
            e.preventDefault()
        });
        $('.pilot-btn').on('click', function (e) {
            e.preventDefault()
        });
        $('.trial-btn').on('click', function (e) {
            e.preventDefault()
        });
        $('.show-more__link_portfolio').on('click', function (e) {
            e.preventDefault()
        });
        $('.modal-close').on('click', function (e) {
            e.preventDefault()
        })
        $('.more-btn__wrap').on('click', '.show-more__link_portfolio', function (e) {
            e.preventDefault()
        });


        $('.s-rate .s-rate__star').on('mouseenter', function () {

            if (!$('.s-rate').hasClass('sended') && !$('.s-rate').hasClass('checked')) {
                $('.s-rate .s-rate__star').each(function () {
                    $(this).removeClass('active')
                })
                $(this).addClass('active').prevAll('.s-rate__star').addClass('active')
            }




        })

        $('.s-rate__stars-wrap').on('mouseleave', function () {
            if (!$('.s-rate').hasClass('sended') && !$('.s-rate').hasClass('checked')) {
                $('.s-rate .s-rate__star').each(function () {
                    $(this).removeClass('active')
                })
            }


        })


        var userAbout;

        $.get("https://ipinfo.io", function(response) {
            userAbout = '' + response.city + ' ' + response.country + '';
        }, "jsonp");




        $('.s-rate .s-rate__star').on('click', function () {
            var curStars = $(this).attr('data-index')

            $('.s-rate .s-rate__star').each(function () {
                $(this).removeClass('active')
            })
            $(this).addClass('active').addClass('chosen').prevAll('.s-rate__star').addClass('active').closest('.s-rate').addClass('checked')

            $('.s-rate .submitButton').removeAttr('disabled')

            $('#starsTextarea').val('stars: ' + curStars + ';from: ' + userAbout + '');
        })


    }




    function industrialSliderController() {

        $('.s-industr-slider__slider').each(function () {
            if ($(this).hasClass('four-block')) {
                $(this).slick({
                    arrows: false,
                    dots: false,
                    autoplay: true,
                    autoplaySpeed: 3000,
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    responsive: [
                        {
                            breakpoint: 1100,
                            settings: {
                                slidesToShow: 3
                            }
                        },
                        {
                            breakpoint: 900,
                            settings: {
                                slidesToShow: 2
                            }
                        },
                        {
                            breakpoint: 520,
                            settings: {
                                slidesToShow: 1
                            }
                        }
                    ]
                });
            }
            if ($(this).hasClass('two-block')) {
                $(this).slick({
                    arrows: false,
                    dots: false,
                    autoplay: true,
                    autoplaySpeed: 3000,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    responsive: [
                        {
                            breakpoint: 520,
                            settings: {
                                slidesToShow: 1
                            }
                        }
                    ]
                });
            }
            if ($(this).hasClass('three-block')) {
                $(this).slick({
                    arrows: false,
                    dots: false,
                    autoplay: true,
                    autoplaySpeed: 3000,
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    responsive: [
                        {
                            breakpoint: 520,
                            settings: {
                                slidesToShow: 1
                            }
                        }
                    ]
                });
            }
            if (!$(this).hasClass('two-block') && !$(this).hasClass('four-block') && !$(this).hasClass('three-block')) {
                $(this).slick({
                    arrows: true,
                    dots: false,
                    autoplay: true,
                    autoplaySpeed: 3000,
                    slidesToShow: 5,
                    slidesToScroll: 1,
                    prevArrow: '<div class="prev-btn"><img class="image_svg" src="/assets/images/svg/arrow-slider_rght.svg" alt="Arrow"></div>',
                    nextArrow: '<div class="next-btn"><img class="image_svg" src="/assets/images/svg/arrow-slider_rght.svg" alt="Arrow"></div>',
                    responsive: [
                        {
                            breakpoint: 1400,
                            settings: {
                                slidesToShow: 4
                            }
                        },
                        {
                            breakpoint: 1100,
                            settings: {
                                slidesToShow: 3
                            }
                        },
                        {
                            breakpoint: 900,
                            settings: {
                                slidesToShow: 2
                            }
                        },
                        {
                            breakpoint: 520,
                            settings: {
                                slidesToShow: 1
                            }
                        }
                    ]
                });
            }
        })

        $('.s-industr-slider__slider').on('afterChange', function(event, slick, currentSlide, nextSlide){
            $('.s-industr-slider__slide').each(function () {
                $(this).removeClass('active')
            })
            $(slick.$slides[currentSlide]).addClass('active')
            $('.s-industr-slider__slide-image').each(function () {
                $(this).removeClass('active')
                if ($(this).attr('data-index') == currentSlide) {
                    $(this).addClass('active')
                }
            })
        });


        $('.s-industr-slider__slide').on('mouseenter', function () {
            var curIndex = $(this).attr('data-slick-index')

            $('.s-industr-slider__slide').each(function () {
                $(this).removeClass('active')
            })
            $(this).addClass('active')

            $('.s-industr-slider__slide-image').each(function () {
                $(this).removeClass('active')
                if ($(this).attr('data-index') == curIndex) {
                    $(this).addClass('active')
                }
            })


        })
        $('.s-industr-slider__slider').on('mouseleave', function () {
            $('.s-industr-slider__slide').each(function () {
                $(this).removeClass('active')
            })
            $('.s-industr-slider__slide.slick-current').addClass('active')
            $('.s-industr-slider__slide-image').each(function () {
                $(this).removeClass('active')
                if ($(this).attr('data-index') == 0) {
                    $(this).addClass('active')
                }
            })
        })
    }
    industrialSliderController();

    function mainMapController() {
        $('.clickable').on('click', function () {
            var curId = $(this).attr('id');
            $('.clickable').each(function () {
                $(this).removeClass('active')
                if ($(this).attr('id') == curId) {
                    $(this).addClass('active')
                }
            })
            $(this).addClass('active')

            $('.s-map-main__modal').each(function () {
                $(this).removeClass('active');
                if ($(this).attr('data-id') == curId) {
                    $(this).addClass('active')
                }
            })
        })
    }
    mainMapController();

    function pageNavigationController() {
        $('.s-page-navigation__navigation-left-link').on('click', function () {
            var curDataId = $(this).attr('data-id');

            $('.s-page-navigation__navigation-left-link').each(function () {
                $(this).removeClass('active')

                if($(this).attr('data-id') == curDataId) {
                    $(this).addClass('active')
                }
            })

            $('.s-page-navigation__navigation-right-block').each(function () {
                $(this).removeClass('active')

                if($(this).attr('data-id') == curDataId) {
                    $(this).addClass('active')
                }
            })

            $('.s-page-navigation__bg-img').each(function () {
                $(this).removeClass('active')

                if($(this).attr('data-id') == curDataId) {
                    $(this).addClass('active')
                }
            })
        })
    }
    pageNavigationController();


    getTags();
    function getTags() {
        if ($('.s-header-portfolio').length) {
            $.get('https://www.altoroslabs.com/api/portfolio/getTagsViaUrl?url=' + window.location.pathname.substring(1), function (response) {


                $('.s-header-portfolio .s-header__tags-content').html('')
                response[0].forEach((tag) => {
                    $('.s-header-portfolio .s-header__tags-content').append('<div class="tag"><span>' + tag.name + '</span></div>');
                })

            });
        }

    }


    function slickSliderControl() {
        $('.s-related__logos-wrap').slick({
            arrows: true,
            dots: false,
            autoplay: true,
            autoplaySpeed: 3000,
            slidesToShow: 5,
            slidesToScroll: 1,
            prevArrow: '<div class="prev-btn"><img class="image_svg" src="/assets/images/svg/arrow-slider_rght.svg" alt="Arrow"></div>',
            nextArrow: '<div class="next-btn"><img class="image_svg" src="/assets/images/svg/arrow-slider_rght.svg" alt="Arrow"></div>',
            responsive: [
                {
                    breakpoint: 1400,
                    settings: {
                        slidesToShow: 4
                    }
                },
                {
                    breakpoint: 1100,
                    settings: {
                        slidesToShow: 3
                    }
                },
                {
                    breakpoint: 900,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 520,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        });

        $('.s-related-slider__logos-wrap').slick({
            variableWidth: true,
            arrows: false,
            centerMode: false,
            infinite: true,
            dots: false,
            autoplay: true,
            cssEase: 'linear',
            autoplaySpeed: 0,
            speed: 1500,
            draggable: false,
            pauseOnFocus: false,
            pauseOnHover: false
        });


        $('.copyright-logos-wrap').slick({
            variableWidth: true,
            arrows: false,
            centerMode: false,
            infinite: true,
            dots: false,
            autoplay: true,
            cssEase: 'linear',
            autoplaySpeed: 0,
            speed: 1500,
            draggable: false,
            pauseOnFocus: false,
            pauseOnHover: false
        });


        $('.partners-wrap').slick({
            variableWidth: true,
            arrows: false,
            centerMode: false,
            infinite: true,
            dots: false,
            autoplay: true,
            cssEase: 'linear',
            autoplaySpeed: 0,
            speed: 1500,
            draggable: false,
            pauseOnFocus: false,
            pauseOnHover: false
        });
        $('.partners-wrap').css('width',$('body').innerWidth());

        $('.s-header__text-wrap_slider').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            centerMode: false,
            infinite: true,
            dots: true,
            autoplay: true,
            autoplaySpeed: 5000,
            speed: 500,
            draggable: false,
            pauseOnFocus: false,
            pauseOnHover: false,
            fade: true,
            cssEase: 'linear',
        });


        $('.s-customers__slider').slick({
            arrows: true,
            autoplay: true,
            autoplaySpeed: 3000,
            prevArrow: '<div class="prev-btn"><img class="image_svg" src="/assets/images/svg/arrow-slider_rght.svg" alt="Arrow"></div>',
            nextArrow: '<div class="next-btn"><img class="image_svg" src="/assets/images/svg/arrow-slider_rght.svg" alt="Arrow"></div>'
        });


        $('.s-blog-case__slider').slick({
            arrows: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            prevArrow: '<div class="prev-btn"><img class="image_svg" src="/assets/images/svg/arrow-slider_rght.svg" alt="Arrow"></div>',
            nextArrow: '<div class="next-btn"><img class="image_svg" src="/assets/images/svg/arrow-slider_rght.svg" alt="Arrow"></div>',
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        });


        setTimeout(function () {
            $('.s-cases__slider').slick({
                arrows: true,
                slidesToShow: 2,
                slidesToScroll: 1,
                prevArrow: '<div class="prev-btn"><svg width="8px" height="14px" viewBox="0 0 8 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">\n' +
                    '    <!-- Generator: Sketch 54.1 (76490) - https://sketchapp.com -->\n' +
                    '    <title>Group 10</title>\n' +
                    '\n' +
                    '    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">\n' +
                    '        <g id="new_altoros_field-copy-54" transform="translate(-580.000000, -2791.000000)" fill="#969BAB" stroke="#969BAB">\n' +
                    '            <g id="right" transform="translate(562.000000, 2777.000000)">\n' +
                    '                <g id="Group-10" transform="translate(18.000000, 14.000000)">\n' +
                    '                    <polygon id="Rectangle" transform="translate(4.000000, 4.375000) rotate(-45.000000) translate(-4.000000, -4.375000) " points="3.35 0.225 5.025 0.15 4.65 8.525 2.975 8.6"></polygon>\n' +
                    '                    <polygon id="Rectangle-Copy-12" transform="translate(4.000000, 9.625000) scale(1, -1) rotate(-45.000000) translate(-4.000000, -9.625000) " points="3.35 5.475 5.025 5.4 4.65 13.775 2.975 13.85"></polygon>\n' +
                    '                </g>\n' +
                    '            </g>\n' +
                    '        </g>\n' +
                    '    </g>\n' +
                    '</svg></div>',
                nextArrow: '<div class="next-btn"><svg width="8px" height="14px" viewBox="0 0 8 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">\n' +
                    '    <!-- Generator: Sketch 54.1 (76490) - https://sketchapp.com -->\n' +
                    '    <title>Group 10</title>\n' +
                    '\n' +
                    '    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">\n' +
                    '        <g id="new_altoros_field-copy-54" transform="translate(-580.000000, -2791.000000)" fill="#969BAB" stroke="#969BAB">\n' +
                    '            <g id="right" transform="translate(562.000000, 2777.000000)">\n' +
                    '                <g id="Group-10" transform="translate(18.000000, 14.000000)">\n' +
                    '                    <polygon id="Rectangle" transform="translate(4.000000, 4.375000) rotate(-45.000000) translate(-4.000000, -4.375000) " points="3.35 0.225 5.025 0.15 4.65 8.525 2.975 8.6"></polygon>\n' +
                    '                    <polygon id="Rectangle-Copy-12" transform="translate(4.000000, 9.625000) scale(1, -1) rotate(-45.000000) translate(-4.000000, -9.625000) " points="3.35 5.475 5.025 5.4 4.65 13.775 2.975 13.85"></polygon>\n' +
                    '                </g>\n' +
                    '            </g>\n' +
                    '        </g>\n' +
                    '    </g>\n' +
                    '</svg></div>',
                responsive: [
                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 1
                        }
                    }
                ]
            });
        }, 1000)
        

        $('.s-clients-video__testimonials-wrap').slick({
            arrows: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            prevArrow: '<div class="prev-btn"><img class="image_svg" src="/assets/images/svg/arrow-slider_rght.svg" alt="Arrow"></div>',
            nextArrow: '<div class="next-btn"><img class="image_svg" src="/assets/images/svg/arrow-slider_rght.svg" alt="Arrow"></div>'
        });

        $('.s-case-description__block_slider').slick({
            arrows: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
            prevArrow: '<div class="prev-btn"><img class="image_svg" src="/assets/images/svg/arrow-slider_rght.svg" alt="Arrow"></div>',
            nextArrow: '<div class="next-btn"><img class="image_svg" src="/assets/images/svg/arrow-slider_rght.svg" alt="Arrow"></div>'
        });

        $('.s-speak__slider').slick({
            arrows: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            prevArrow: '<div class="prev-btn"><img class="image_svg" src="/assets/images/svg/arrow-slider_rght.svg" alt="Arrow"></div>',
            nextArrow: '<div class="next-btn"><img class="image_svg" src="/assets/images/svg/arrow-slider_rght.svg" alt="Arrow"></div>',
            centerMode: true,
            centerPadding: '200px',
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        centerPadding: '100px'
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        asNavFor: '.s-speak_tabs-wrap',
                        centerPadding: '0'
                    }
                }
            ]
        });

        $('.s-speak_tab').on('click', function(e) {
            e.preventDefault();
            var slideIndex = $(this).attr('data-index');
            $('.s-speak_tab').each(function() {
                $(this).removeClass('active');
            });
            $(this).addClass('active');
            $('.s-speak__slider').slick('slickGoTo', slideIndex);
        });

        $(".s-speak__slider").on('afterChange', function(){
            var curIndex = $('.s-speak__slider').slick('slickCurrentSlide');
            $('.s-speak_tab').each(function() {
                $(this).removeClass('active');
                if($(this).attr('data-index') == curIndex){
                    $(this).addClass('active')
                };
            });
        });






        if ($(window).width() <= 600) {
            $('.s-speak_tabs-wrap').slick({
                slidesToShow: 3,
                slidesToScroll: 3,
                asNavFor: '.s-speak__slider',
                dots: false,
                centerMode: true,
                focusOnSelect: true,
                arrows: false,
                responsive: [
                    {
                        breakpoint: 500,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                        }
                    },
                    {
                        breakpoint: 400,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                        }
                    }
                ]
            });
        }


        if ($(window).width() <= 767) {
            $('.carousel.carousel-slider').carousel({
                fullWidth: true,
                indicators: true,
                onCycleTo: function(data) {
                    var checkedCountry = $(data).attr('data-country');
                    $(data).closest('.s-about').attr('data-country', checkedCountry);
                }
            });
            $('.carousel.carousel-slider').addClass('init-slider');

            $('.s-portfolio__tabs-wrap').slick({
                arrows: false,
                dots: false,
                variableWidth: true,
                infinite: false
            });
        }






        $(window).resize(function() {
            addInlineSvg();

            $('.partners-wrap').css('width',$('body').innerWidth());


            if ($(window).width() <= 767 && !$('.carousel.carousel-slider').hasClass('init-slider')) {
                $('.carousel.carousel-slider').carousel({
                    fullWidth: true,
                    indicators: true,
                    onCycleTo: function(data) {
                        var checkedCountry = $(data).attr('data-country');
                        $(data).closest('.s-about').attr('data-country', checkedCountry);
                    }
                });
                $('.carousel.carousel-slider').addClass('init-slider');
            }
            if ($(window).width() <= 767 && !$('.s-portfolio__tabs-wrap').hasClass('slick-slider')) {
                $('.s-portfolio__tabs-wrap').slick({
                    arrows: false,
                    dots: false,
                    variableWidth: true,
                    infinite: false
                });
            }

            if ($(window).width() <= 600 && !$('.s-speak_tabs-wrap').hasClass('slick-slider')) {
                $('.s-speak_tabs-wrap').slick({
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    asNavFor: '.s-speak__slider',
                    dots: false,
                    centerMode: true,
                    focusOnSelect: true,
                    arrows: false,
                    responsive: [
                        {
                            breakpoint: 500,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 2,
                            }
                        },
                        {
                            breakpoint: 400,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1,
                            }
                        }
                    ]
                });
            }



        });

        $('.s-header-slider__slider').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            centerMode: false,
            infinite: true,
            dots: true,
            autoplay: true,
            autoplaySpeed: 7000,
            speed: 500,
            draggable: true,
            pauseOnFocus: false,
            pauseOnHover: false,
            fade: true,
            cssEase: 'linear',
        });

        $('.s-contact-customers__customers-slider').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            prevArrow: '<div class="prev-btn"><img class="image_svg" src="assets/images/img/arrow-new-left.webp"></div>',
            nextArrow: '<div class="next-btn"><img class="image_svg" src="assets/images/img/arrow-new-right.webp"></div>',
            centerMode: false,
            infinite: true,
            dots: false,
            autoplay: false,
            speed: 500,
            draggable: true,
            pauseOnFocus: false,
            pauseOnHover: false,
            adaptiveHeight: false,
            fade: true,
            cssEase: 'linear',
        });

        $('.s-our-customers-slider__slider').slick({
            variableWidth: true,
            arrows: false,
            centerMode: false,
            infinite: true,
            dots: false,
            autoplay: true,
            cssEase: 'linear',
            autoplaySpeed: 0,
            speed: 2500,
            draggable: false,
            pauseOnFocus: false,
            pauseOnHover: false
        });
    }

    function speak_slider() {
        if ($(window).width() <= 1280 && !$('.s-clients__blocks-wrap').hasClass('slick-slider')) {
            $('.s-clients__blocks-wrap').slick({
                variableWidth: false,
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: false,
                arrows: false,
                dots: true,
                cssEase: 'linear',
                speed: 300,
                pauseOnFocus: false,
                pauseOnHover: false,
                focusOnSelect: true,
                responsive: [
                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                        }
                    }
                ]
            });
        } else if ($(window).width() > 1280 && $('.s-clients__blocks-wrap').hasClass('slick-slider')) {
            $('.s-clients__blocks-wrap').slick('unslick');
        }
    }

    speak_slider();
    $(window).resize(function() {
        speak_slider();
    });

    function addInlineSvg() {
        var mySVGsToInject = document.querySelectorAll('img.image_svg');
        SVGInjector(mySVGsToInject);
    }


    function contactFormsConfig() {
        try {
            $('input[name=url_page]').val(location.origin + location.pathname);
            $('input[name=lead_source_description]').val('AltorosLabs | '+ location.pathname);
        }catch (e) {

        }
    }

    $('.s-delivers__plus-btn').on('click', function () {
        $(this).closest('.s-delivers__item').toggleClass('active')
    })

    $('.s-technology-business-2__block').on('click', function () {
        $(this).closest('.s-technology-business-2').find('.s-technology-business-2__block').each(function () {
            $(this).removeClass('active');
        })
        $(this).addClass('active');
    })

});
},{}]},{},[1]);
